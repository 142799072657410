import { Title, Button } from 'account-ui-components'
import Page from '@/components/Page/Page.vue'
import config from '@/api/config';
import { getEnv } from '@/helpers';

export default {
  name: 'LogoutConfirmation',
  props: {
    redirectUrl: {
      type: String,
      required: true
    },
    hasAutoRedirect: {
      type: Boolean
    }
  },
  components: {
    Page,
    Title,
    Button
  },

  setup(props) {
    const env = getEnv()

    if (props.hasAutoRedirect && props.redirectUrl) {
      const { profile } = config.hosts[env]
      window.location.assign(`${profile}/callback-signout.html?redirectUrl=${encodeURIComponent(props.redirectUrl)}`);
    }

    const onConfirmClick = () => {
      window.location.assign(props.redirectUrl || '/account/login')
    }

    return {
      onConfirmClick
    }
  }
}
